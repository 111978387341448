import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { format, unit, type Unit } from "mathjs";

const NON_BREAKING_SPACE = "\u00A0";
const PRIME_CHARACTER = "\u2032";
const DOUBLE_PRIME_CHARACTER = "\u2033";
const FRACTION_SLASH_CHARACTER = "\u2044";

/** Known measurement units */
export enum MeasurementUnits {
  // metric
  meters = "meters",
  centimeters = "centimeters",
  millimeters = "millimeters",

  // us/imperial
  feet = "feet",
  inches = "inches",
}

/** Metadata associated to each unit of measure */
type MeasurementUnitsData = {
  /** The type of unit of measure (metric or imperial) */
  type: SupportedUnitsOfMeasure;
  /** The string representing the unit of measure symbol (e.g mm for millimeters) */
  unit: string;
};

export const MeasurementUnitsInfo: Readonly<
  Record<MeasurementUnits, MeasurementUnitsData>
> = {
  // metric
  meters: {
    type: "metric",
    unit: "m",
  },
  centimeters: {
    type: "metric",
    unit: "cm",
  },
  millimeters: {
    type: "metric",
    unit: "mm",
  },
  // us/imperial
  feet: {
    type: "us",
    unit: "ft",
  },
  inches: {
    type: "us",
    unit: "in",
  },
};

export type Measurement = {
  /** Value of the measurement */
  measurementValue?: number;

  /** measurement unit selected */
  measurementUnit: MeasurementUnits;
};

// Precision at which the inches should be in fractions.
// E.g: 2 means 1/2 inches; 16 means 1/16 inches; 1 means no fraction
type InchesPrecision = 1 | 2 | 4 | 8 | 16;

type ConvertMeterToFeetInchesProps = {
  /** Value in meters, to be converted to feet and inches */
  meters: number;

  /**
   * Precision at which the inches should be in fractions
   *
   * @default 16
   */
  precision?: InchesPrecision;
};

/**
 * Regular expression for sanity check of the labels. We accept decimal numbers like X.Y or
 * fractional ones (only for feet) like X Y/Z.
 * In case of a decimal number, the whole number is stored in the group 6, while
 * in case of a fractional number, the feet are stored in group 2, while the numerator
 * and denominator are stored respectively in groups 4 and 5.
 */
const REG_EXPRESSION =
  /^(([0-9]+)([ ]([0-9]|[1][0-1])[/]([0-9]|[1][0-2]))?)$|^([0-9]+([.][0-9]+)?)$/;

/**
 * Convert a value in meters in a string that can be displayed and edited by the user
 * The output syntax could be:
 *  - decimal: XXX.YYYYY
 *  - fractional: X Y/Z
 *
 * @param meters The input value in meters
 * @param to The unit of measure to which we want convert the value in meters
 * @returns The converted value in meters
 */
export function metersToLabel(meters: number, to: MeasurementUnits): string {
  const value = unit(meters, MeasurementUnits.meters).to(to).toNumber();
  switch (to) {
    case MeasurementUnits.meters:
    case MeasurementUnits.centimeters:
    case MeasurementUnits.millimeters:
    case MeasurementUnits.inches: {
      return value.toFixed(3);
    }
    case MeasurementUnits.feet: {
      const inches = Math.round((value % 1) * 12);
      const reminder = inches === 12 ? 1 : 0;
      const feet = Math.floor(value) + reminder;
      return inches > 0 && reminder === 0 ? `${feet} ${inches}/12` : `${feet}`;
    }
  }
}

/**
 * Convert a string that the user can write and convert it a value in meters, if possible.
 * The supported syntax is:
 *  - decimal: XXX.YYYYY
 *  - fractional: X Y/Z
 *
 * @param label The text to parse and convert
 * @param from The unit of measure in which the label was written
 * @returns The converted value in meters
 */
export function labelToMeters(
  label: string,
  from: MeasurementUnits,
): number | undefined {
  if (label.length === 0) {
    return;
  }

  switch (from) {
    case MeasurementUnits.meters:
    case MeasurementUnits.centimeters:
    case MeasurementUnits.millimeters:
    case MeasurementUnits.inches: {
      const value = Number(label);
      if (isNaN(value)) {
        return;
      }
      return unit(value, from).to(MeasurementUnits.meters).toNumber();
    }
    case MeasurementUnits.feet: {
      const value = Number(label);
      if (!isNaN(value)) {
        return unit(value, from).to(MeasurementUnits.meters).toNumber();
      }
      const matches = REG_EXPRESSION.exec(label);
      if (!matches) {
        return;
      }
      // Handle fractional feet
      if (
        matches[2] &&
        matches[2].length > 0 &&
        matches[4] &&
        matches[4].length > 0 &&
        matches[5] &&
        matches[5].length > 0
      ) {
        const feet = Number(matches[2]);
        const inches = Number(matches[4]) / Number(matches[5]);
        const value = feet + inches;
        return unit(value, from).to(MeasurementUnits.meters).toNumber();
      }
    }
  }
}

/**
 * Check if the input string is in a valid format for the selected unit of measure
 *
 * @param text The text to validate
 * @param unit The current unit of measure selected by the user
 * @returns True if the input text can be correctly converted
 */
export function validateMeasurementLabel(
  text: string,
  unit: MeasurementUnits,
): boolean {
  return !!labelToMeters(text, unit);
}

/**
 * Convert the input value in meters to nicely formatted label that can be used
 * for display only.
 *
 * @returns feet and inches in string, converted from given value in meters
 */
export function metersToFormattedFractional({
  meters,
  precision = 16,
}: ConvertMeterToFeetInchesProps): string {
  if (meters === 0) {
    return `0${DOUBLE_PRIME_CHARACTER}`;
  }

  const [feet, inches] = unit(meters, MeasurementUnits.meters).splitUnit([
    MeasurementUnits.feet,
    MeasurementUnits.inches,
  ]);

  const formattedInches = Math.abs(inches.toNumber());

  // Precision 14 is given to eliminate round off errors
  // https://mathjs.org/docs/datatypes/numbers.html#round-off-errors
  const formattedFeet = feet.equals(unit(0, MeasurementUnits.feet))
    ? ""
    : format(feet, { precision: 14 }).replace(
        " feet",
        `${PRIME_CHARACTER + NON_BREAKING_SPACE}`,
      );

  return `${
    formattedFeet +
    formatFractionNumber(formattedInches, precision) +
    DOUBLE_PRIME_CHARACTER
  }`;
}

/**
 * @param value Number to be converted to fraction
 * @param denominator the precision to be used as denominator
 * @returns the formatted fraction for a given number
 */
export function formatFractionNumber(
  value: number,
  denominator: number,
): string {
  if (denominator === 0) {
    throw new Error("formatFractionNumber: denominator cannot be zero");
  }

  const absoluteValue = Math.abs(value);
  let intPart = Math.trunc(absoluteValue);
  let numerator = Math.round((absoluteValue - intPart) * denominator);

  // numerator and denominator are the same, add it to the integer
  if (numerator === denominator) {
    numerator = 0;
    intPart += 1;
  } else {
    // reduce fraction
    // Not using the fraction function from mathjs (https://mathjs.org/docs/datatypes/fractions.html) because it returns improper fraction
    // and not the mixed fraction.
    for (let i = numerator; i > 1; i--) {
      if (denominator % i === 0 && numerator % i === 0) {
        denominator /= i;
        numerator /= i;
      }
    }
  }

  let result: string = "";

  if (value < 0) {
    result += "-";
  }

  if (intPart > 0 || numerator === 0) {
    result += intPart;
  }

  if (numerator > 0) {
    if (result.length > 0) {
      result += NON_BREAKING_SPACE;
    }

    result += `${numerator + FRACTION_SLASH_CHARACTER + denominator}`;
  }

  return result;
}

/**
 * @param precision as number of significant digits after decimal point in meters; undefined or InchesPrecision means use default value = 3
 * @returns the value in meters matching the precision (e.g. 0.01 when precision is 2)
 */
function getMinimumValueFromMeterPrecision(
  precision: InchesPrecision | number | undefined,
): number {
  if (typeof precision !== "number") {
    // default precision is 1 mm
    return 0.001;
  }
  // do not try to optimize this call with switch/case as performance gain is very inconsistent
  // prefer encapsulate this call with useMemo instead
  return Math.pow(10, -precision);
}

/**
 * @param precision maximum number of significant digits after decimal point in meters; undefined means default value = 3
 * @returns the units to be used for the given precision when values is bellow 1 meter
 */
function getUnitsFromMetricPrecision(precision: number | undefined): string {
  // "mm" is default  and also the smallest units
  if (precision === undefined || precision === 3) return "mm";
  // "cm" is the only units matching one very specific precision
  if (precision === 2) return "cm";
  // "m" is the largest units for precision 1 and 0
  if (precision === 0) return "m";
  // other precisions are not supported and not relevant
  throw new Error(`Unsupported precision: ${precision}`);
}

/**
 * @param distanceInMeters distance, in meters, to format
 * @param unitOfMeasure unit of measure to format the distance to
 * @param precision when unitOfMeasure is "us", precision as fraction of inches (e.g. 16 means 1/16 inches);
 *  when unitOfMeasure is "metric", precision as decimal fraction of meter (e.g. 3 means mm); only 0, 2, and 3 are accepted.
 *  undefined means default precision: 16 (1/16 inches) for "us"; 3 (mm) precision for "metric"
 * @returns the formatted distance
 * When unitOfMeasure is "us", the formatted distance will include the number of foot when distanceInMeters >= 1 ft,
 * then number of inches if not 0 or if distanceInMeters < 1 ft, and eventually the fraction of inch according to precision value.
 * When unitOfMeasure is "metric", the formatted distance will be in meters when distanceInMeters>=1.0 m,
 * but will use the sub-units matching precision otherwise, with all significant digits for this precision
 * (e.g. for precision=2, 123.456 meters will be reported as "123.46 m", and 0.123 meters as "12 cm").
 * For all unitOfMeasure, spaces between values and units are non-breaking spaces.
 */
export function humanReadableDistance(
  distanceInMeters: number,
  unitOfMeasure: SupportedUnitsOfMeasure,
  precision?: InchesPrecision | number,
): string {
  switch (unitOfMeasure) {
    case "us":
      return metersToFormattedFractional({
        meters: distanceInMeters,
        precision:
          precision && typeof precision !== "number" ? precision : undefined,
      });
    case "metric": {
      const minimumValueInMeter = getMinimumValueFromMeterPrecision(precision);
      if (Math.abs(distanceInMeters) < minimumValueInMeter) {
        return `0${NON_BREAKING_SPACE}${getUnitsFromMetricPrecision(precision)}`;
      }
      if (Math.abs(distanceInMeters) < 1) {
        // display the value in mm as an integer
        return `${Math.round(distanceInMeters / minimumValueInMeter)}${NON_BREAKING_SPACE}${getUnitsFromMetricPrecision(precision)}`;
      }

      // display the value in meters with 3 digits, truncated according to the precision
      const roundedValue =
        Math.round(distanceInMeters / minimumValueInMeter) *
        minimumValueInMeter;
      return `${format(roundedValue, { notation: "fixed", precision: precision ?? 3 })}${NON_BREAKING_SPACE}m`;
    }
  }
}

/**
 * @param areaInMetersSq area, in meters squared, to format
 * @param unitOfMeasure unit of measure to format the area to
 * @returns the formatted area
 */
export function humanReadableArea(
  areaInMetersSq: number,
  unitOfMeasure: SupportedUnitsOfMeasure,
): string {
  switch (unitOfMeasure) {
    case "us": {
      const feet = unit(areaInMetersSq, "m^2").to("feet^2");
      return `${format(feet.toNumber(), { precision: 3 })}${NON_BREAKING_SPACE}ft\u00B2`;
    }
    default:
      return `${format(areaInMetersSq, { precision: 3 })}${NON_BREAKING_SPACE}m\u00B2`;
  }
}

/**
 * @returns The converted value for given unit to meter
 * @param value Value to convert in meter
 * @param from The unit from which the value must be converted to meter
 */
export function convertToMeter(value: number, from: MeasurementUnits): number {
  return unit(value, from).to(MeasurementUnits.meters).toNumber();
}

/**
 * @returns The converted value in meter to the target unit
 * @param value Value in meter to convert
 * @param to The unit to which the value in meter must be converted
 */
export function convertFromMeter(value: number, to: MeasurementUnits): number {
  return unit(value, MeasurementUnits.meters).to(to).toNumber();
}

/**
 * @returns The converted value from given unit to another unit
 * @param value Value to convert in a given unit
 * @param from The unit from which the value be converted from
 * @param to The unit to which the value be converted to
 */
export function convertUnit(
  value: number,
  from: MeasurementUnits,
  to: MeasurementUnits,
): number {
  return unit(value, from).to(to).toNumber();
}

/**
 * Check the given string is a unit
 *
 * @param unit string to validate as unit
 * @returns true if the given string is a unit
 */
export function isMeasurementUnit(unit: string): unit is MeasurementUnits {
  return Object.keys(MeasurementUnits).includes(unit);
}

/** Threshold when to switch to the next unit. */
const UNIT_THRESHOLD = 10;

type DynamicDistanceLabelProps = {
  /** The original distance value. */
  value: number;
  /** The unit that the distance value is provided in. */
  from: MeasurementUnits;
  /** The measurement system that the label should be returned in. */
  system: SupportedUnitsOfMeasure;
  /**
   * The precision to use for metric values.
   *
   * @default 3
   */
  metricPrecision?: number;
  /**
   * The precision to use for US/imperial values.
   *
   * @default 16
   */
  usPrecision?: InchesPrecision;
};

/**
 * Generate a human-readable distance label, where the unit is chosen dynamically based on the distance.
 * For example, small distances might be given in mm while bigger distances are displayed in m.
 * Warning: unlike humanReadableDistance, dynamicDistanceLabel is using a standard space before units symbol.
 * When preferring a non-breaking space, call humanReadableDistance.
 * TO DO: address duplication with humanReadableDistance in https://faro01.atlassian.net/browse/CADBIM-830
 *
 * @returns The human readable distance label. Includes both value and unit.
 */
export function dynamicDistanceLabel({
  value,
  from,
  system,
  metricPrecision = 3,
  usPrecision = 16,
}: DynamicDistanceLabelProps): string {
  switch (system) {
    case "us":
      return metersToFormattedFractional({
        meters: convertToMeter(value, from),
        precision: usPrecision,
      });
    case "metric":
      return chooseMetricDistanceUnit(unit(value, from)).format({
        notation: "fixed",
        precision: metricPrecision,
      });
  }
}

/**
 * @param unitValue The value to change the distance unit for.
 * @returns The best fitting unit for the distance.
 */
function chooseMetricDistanceUnit(unitValue: Unit): Unit {
  const m = unitValue.to(MeasurementUnitsInfo[MeasurementUnits.meters].unit);

  if (m.toNumber() >= UNIT_THRESHOLD) {
    return m;
  }

  const cm = unitValue.to(
    MeasurementUnitsInfo[MeasurementUnits.centimeters].unit,
  );

  if (cm.toNumber() >= UNIT_THRESHOLD) {
    return cm;
  }

  const mm = unitValue.to(
    MeasurementUnitsInfo[MeasurementUnits.millimeters].unit,
  );
  return mm;
}
